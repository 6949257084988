<template>
  <v-dialog v-model="showModal" max-width="600px" scrollable @click:outside="handleClose">
    <v-card>
      <v-toolbar color="info" dark dense>
        <v-toolbar-title>コメント{{ items.length }}件</v-toolbar-title>
        <v-spacer></v-spacer>

        <v-btn icon @click="handleClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-list>
          <template v-for="item in items">
            <v-list-item :key="item.title" ripple class="vr_followWrapper">
              <v-list-item-content>
                <v-list-item-subtitle class="font-weight-bold d-flex"
                  >{{ item.create_user_name }}
                  <v-list-item-subtitle class="font-italic text-caption text-end">{{
                    item.create_datetime
                  }}</v-list-item-subtitle>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="wrap-text"
                  ><div>{{ item.subtitle }}</div></v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>

        <v-textarea
          v-if="role"
          outlined
          placeholder="コメントを追加..."
          v-model="comment"
          class="textarea"
          rows="3"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        通知先：
        <v-checkbox
          v-for="(constant, index) in constants"
          v-model="noticeTeams"
          :label="constant.name"
          :value="constant.name"
          :key="index"
        ></v-checkbox>
        <v-spacer> </v-spacer>
        <v-btn outlined @click="handleClose">閉じる</v-btn>
        <v-btn v-if="role" outlined color="primary" @click="commentRegist" :disabled="!isValid()">投稿する</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Object || Array,
    },
    role: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      comment: '',
      noticeTeams: [],
    };
  },
  computed: {
    ...mapGetters({
      constants: 'constantMaster/constantList',
    }),
  },
  created() {
    this.apiGetConstantList(['COMMENT_NOTICE_TEAM']);
  },
  emits: ['close', 'commentRegist'],
  methods: {
    ...mapActions({
      apiGetConstantList: 'api/getConstantList',
    }),
    isValid() {
      // \u{3000}: 全角スペース
      return !/^[\s\u{3000}]*$/.test(this.comment);
    },
    handleClose() {
      this.$emit('close', false);
    },
    commentRegist() {
      this.$emit('commentRegist', this.comment, this.noticeTeams);
      this.comment = '';
    },
  },
};
</script>
<style scoped>
div.v-card__text {
  white-space: pre-wrap;
}
.textarea {
  margin-top: 25px;
}
.vr_followWrapper {
  border-top: 1px solid #e7e7e7;
}
.wrap-text {
  text-overflow: inherit;
  white-space: unset;
}
</style>
